// Util

'use strict'

import SmoothScroll from 'smooth-scroll';

export default class {
  constructor(){

    window.onload = function(){
      document.body.classList.add('page--show');
    }

    setTimeout(function(){
      document.body.classList.add('page--show');
    }, 5000);

    this.transitionEndEvents = [
      "webkitTransitionEnd",
      "mozTransitionEnd",
      "oTransitionEnd",
      "transitionend"
    ];
    this.transitionEnd = this.transitionEndEvents.join(" ");

    this.animationEndEvents = [
      "webkitAnimationEnd",
      "mozAnimationEnd",
      "oAnimationEnd",
      "animationend"
    ];
    this.animationEnd = this.animationEndEvents.join(" ");

    this.is = {};

    this.getIs();
    this.scroll();
    this.browser();
  }

  getIs(){
    this.is.touchDevice = ('ontouchstart' in window || navigator.msPointerEnabled) ? true : false;
    this.is.sp_s = window.matchMedia('(max-width:320px)').matches;
    this.is.sp_m = window.matchMedia('(max-width:480px)').matches;
    this.is.sp_l = window.matchMedia('(max-width:640px)').matches;
    this.is.tab_p = window.matchMedia('(max-width:768px)').matches;
    this.is.tab_l = window.matchMedia('(max-width:1040px)').matches;
    this.is.xl = window.matchMedia('(max-width:1280px)').matches;
  }

  scroll(){
    new SmoothScroll('a[data-smooth]', {
      speed: 400,
      easing: 'easeInOutQuart',
      speedAsDuration: true,
      updateURL: false
    });
  }

  browser(){
    // Get the user-agent string
    let userAgentString = navigator.userAgent;
    console.log(userAgentString)

    // Detect Chrome
    let chromeAgent = 
        userAgentString.indexOf("Chrome") > -1
        || userAgentString.indexOf("CriOS") > -1
        ;

    // Detect Internet Explorer
    let EdgeAgent = 
        userAgentString.indexOf("Edge") > -1;

    // Detect Firefox
    let firefoxAgent = 
        userAgentString.indexOf("Firefox") > -1;

    // Detect Safari
    let safariAgent = 
        userAgentString.indexOf("Safari") > -1;
          
    // Discard Safari since it also matches Chrome
    if ((chromeAgent) && (safariAgent)) 
        safariAgent = false;

    // Detect Opera
    let operaAgent = 
        userAgentString.indexOf("OP") > -1;
          
    // Discard Chrome since it also matches Opera     
    if ((chromeAgent) && (operaAgent)) 
        chromeAgent = false;

    if( operaAgent ) document.body.classList.add('opera');
    if( safariAgent ) document.body.classList.add('safari');
    if( chromeAgent ) document.body.classList.add('chrome');
    if( firefoxAgent ) document.body.classList.add('firefox');
    if( EdgeAgent ) document.body.classList.add('edge');
  }

  random(min, max){
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  /*
   * Usage 
   * util.getCookie('accessed')
   */
  getCookie(key){
    var ck = {};
    var key = key;
    var r = document.cookie.split(';');

    r.forEach(function(value) {
      var content = value.split('=');
      var key = content[0];
      var value = content[1];
      ck[key.trim()] = value;
    });

    if( ck[key] ){
      return ck[key];
    }
    else {
      return false;
    }
  }

  /*
   * Usage 
   * util.setCookie('accessed', 'fish', '/', '', '', '');
   */
  setCookie(key, value, path, domain, maxAge, expires){
    var ckstr = "";

    if( key && value ){
      ckstr += key+"="+encodeURIComponent(value);
    }

    if( path ){
      ckstr += "; path="+path;
    }

    if( domain ){
      ckstr += "; domain='"+domain+"'";
    }

    if( maxAge ){
      ckstr += "; max-age="+maxAge;
    }

    if( expires ){
      // expires = new Date(expires);
      ckstr += "; expires="+expires;
    }

    document.cookie = ckstr;
  }

}