// Home

'use strict';

import anime from 'animejs';
import imagesLoaded from 'imagesloaded';
import $ from 'jquery';

export default class {
  constructor() {
    var self = this;

    imagesLoaded(document.getElementById('HomeMainVisualImage'), function(){
      anime({
        targets: ".HomeMainVisualImage",
        opacity: [0,1],
        duration: 1200,
        easing: "easeOutQuad"
      });
  
      anime({
        targets: ".HomeMainVisualLink",
        opacity: [0,1],
        duration: 1200,
        delay: 1800,
        easing: "easeOutQuad"
      });
    });

    if( window.location.href.indexOf('shop.kanu-fks.com') !== -1 ){
      document.body.classList.add('is-shop');
    }
  }

  onready() {
    var self = this;
  }
}