// Shop

'use strict';

import $ from 'jquery';
import 'slick-carousel';

export default class {
  constructor() {
  }

  onready(){    
    $('.SweetsDetailImage').slick({
      fade: true,
      dots: true,
      arrows: false,
      spped: 1200
    });

    $('.purchaseButton__btn--addToCart').text('Add to Cart');
  }
}