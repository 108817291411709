// Common

'use strict';

import anime from 'animejs';
import $ from 'jquery';

export default class {
  constructor() {
    var self = this;

    self.delay = $('.HomeMainVisual').length>0?1000:0;
    self.gnavAnime();

    anime({
      targets: ".GlobalHeaderLogo",
      opacity: [0,1],
      translateY: [-30,0],
      duration: 1000,
      delay: self.delay,
      easing: 'easeOutQuad'
    });

    anime({
      targets: ".GlobalNavBtn i",
      opacity: [0,1],
      translateX: [30,0],
      delay: self.delay,
      easing: 'easeOutQuad',
      delay: function(el,i,l){
        return i * 120 + self.delay;
      }
    });

    anime({
      targets: ".PageTitle",
      opacity: [0,1],
      translateY: [-30,0],
      duration: 1000,
      delay: self.delay + 200,
      easing: 'easeOutQuad'
    });

    let $sweetsContainer = $('.SweetsList').length > 0 ? $('.SweetsList') : $('.base_items_list');
    let sweetsSelector = $('.SweetsList').length > 0 ? ".SweetsList > li" : ".base_item";
    let itemanime = anime({
      targets: sweetsSelector,
      opacity: [0,1],
      translateY: [-30,0],
      duration: 1400,
      easing: 'easeOutQuad',
      autoplay: false,
      delay: function(el,i,l){
        return i * 80;
      }
    });

    $(window).on('load scroll', function(){
      if( $sweetsContainer.length > 0 ){
        if( $(window).scrollTop() + window.innerHeight*3/5 > $sweetsContainer.offset().top ){
          if( !$sweetsContainer.hasClass('animated') ){
            itemanime.play();
            $sweetsContainer.addClass('animated');
          }
        }
      }
    });
  }

  onready(){
    var self = this;

    // scroll
    $(window).on('scroll', function(){
      if( $(window).scrollTop() > window.innerHeight ){
        if( !$('body').hasClass('top--scrolled') ){
          $('body').addClass('top--scrolled');
        }
      }
      else {
        $('body').removeClass('top--scrolled'); 
      }
    });

    //
    $('.js--scroll, .base_item').each(function(){
      let $this = $(this);
      let offset = $(this).offset().top;

      $(window).on('load scroll', function(){
        if( $(window).scrollTop() + window.innerHeight*3/5 > offset ){
          if( !$this.hasClass('animation') ){
            $this.addClass('animation');
          }
        }
      });
    });
  }

  gnavAnime(d){
    var self = this;

    let delay = d ? d : self.delay;

    anime({
      targets: ".GlobalNavList li",
      opacity: [0,1],
      translateY: [-30,0],
      duration: 1000,
      easing: 'easeOutQuad',
      delay: function(el,i,l){
        return i * 80 + delay;
      }
    });
  }

  gnavAnimeStopRain(d){
    var self = this;

    let delay = d ? d : self.delay;

    anime({
      targets: ".GlobalNavList li",
      opacity: [1,0],
      translateY: [0,-30],
      duration: 1000,
      easing: 'easeOutQuad',
      delay: anime.stagger(80, {direction: 'reverse'})
    });
  }
}