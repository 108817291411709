// app.js

import $ from 'jquery';
import 'what-input';

import Util from './module/Util';
let util = new Util();

import Menu from './module/Menu';
let menu = new Menu($('.GlobalNavBtn'));

import Common from './page/Common';
let common = new Common();

import Home from './page/Home';
let home = new Home();

import About from './page/About';
let about = new About();

import Shop from './page/Shop';
let shop = new Shop();

$(function(){

  if( window.location.href.indexOf('/about') >= 0 ){
    about.onready();
  }
  if( window.location.href.indexOf('shop') >= 0 ){
    shop.onready();
  }
  if( window.document.querySelector('body').classList.contains('page-home') ) {
    home.onready();
  }
  
  common.onready();
  
});