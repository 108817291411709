// About

'use strict';

import anime from 'animejs';
import $ from 'jquery';
import 'slick-carousel';

export default class {
  constructor() {
    var self = this;

    anime({
      targets: "#AboutImage1",
      opacity: [0,1],
      translateY: [-30,0],
      delay: 200,
      easing: 'easeOutQuad'
    });

    anime({
      targets: "#AboutAwa",
      opacity: [0,1],
      translateY: [-30,0],
      delay: 500,
      easing: 'easeOutQuad'
    });

    anime({
      targets: "#AboutText",
      opacity: [0,1],
      translateY: [-30,0],
      delay: 700,
      easing: 'easeOutQuad'
    });
  }

  onready(){
    var self = this;
    
    $('.AboutSlider').slick({
      fade: true,
      autoplay: true,
      autoplaySpeed: 4000,
      dots: false,
      arrows: true,
      spped: 1200
    });
  }
}